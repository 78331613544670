<div class="page-wrapper">
  <div class="component-wrapper">
    <h1 class="mat-h1">{{T.PP.TITLE|translate}}</h1>

    <div
      [@standardList]="(projectService.list$|async)?.length"
      [dragulaModel]="projectService.list$|async"
      class="project-list"
      dragula="PROJECTS"
    >
      <mat-card
        *ngFor="let project of (projectService.list$|async); trackBy:trackById;"
        class="project mat-elevation-z0"
      >
        <div
          [ngStyle]="getThemeColor(project.theme?.primary || project.themeColor)"
          class="project-color drag-handle"
        >
          <mat-icon
            class="color-contrast"
            svgIcon="drag_handle"
          ></mat-icon>
        </div>

        <div
          [class.isCurrent]="project.id === (workContextService.activeWorkContextId$|async)"
          class="title"
        >
          {{project.title}}
        </div>

        <div class="issue-providers">
          <mat-icon
            *ngIf="project.issueIntegrationCfgs?.JIRA?.isEnabled"
            [title]="T.PP.JIRA_CONFIGURED|translate"
            svgIcon="jira"
          ></mat-icon>
          <mat-icon
            *ngIf="project.issueIntegrationCfgs?.GITHUB?.repo"
            [title]="T.PP.GITHUB_CONFIGURED|translate"
            svgIcon="github"
          ></mat-icon>
          <mat-icon
            *ngIf="project.issueIntegrationCfgs?.GITLAB?.project"
            [title]="T.PP.GITLAB_CONFIGURED|translate"
            svgIcon="gitlab"
          ></mat-icon>
          <mat-icon
            *ngIf="project.issueIntegrationCfgs?.OPEN_PROJECT?.projectId"
            [title]="T.PP.OPEN_PROJECT_CONFIGURED|translate"
            svgIcon="open_project"
          ></mat-icon>
        </div>

        <div class="controls">
          <button
            (click)="edit(project)"
            [title]="T.PP.EDIT_PROJECT|translate"
            color=""
            mat-icon-button
          >
            <mat-icon>edit</mat-icon>
          </button>
          <!--          <button (click)="archive(project.id)"-->
          <!--                  [disabled]="project.id === (workContextService.activeWorkContextId$|async)"-->
          <!--                  [title]="T.PP.ARCHIVE_PROJECT|translate"-->
          <!--                  color=""-->
          <!--                  mat-icon-button>-->
          <!--            <mat-icon>archive</mat-icon>-->
          <!--          </button>-->
          <!--          <button (click)="export(project.id, project.title)"-->
          <!--                  [title]="T.PP.EXPORT_PROJECT|translate"-->
          <!--                  class="hide-xs"-->
          <!--                  color=""-->
          <!--                  mat-icon-button>-->
          <!--            <mat-icon>flight</mat-icon>-->
          <!--          </button>-->

          <button
            (click)="toggleHideFromMenu(project.id)"
            [title]="(project.isHiddenFromMenu?T.PP.CLICK_TO_SHOW:T.PP.CLICK_TO_HIDE)|translate"
            color=""
            mat-icon-button
          >
            <mat-icon *ngIf="!project.isHiddenFromMenu">visibility</mat-icon>
            <mat-icon *ngIf="project.isHiddenFromMenu">visibility_off</mat-icon>
          </button>
          <button
            (click)="remove(project.id)"
            [disabled]="project.id === (workContextService.activeWorkContextId$|async)"
            [title]="T.PP.DELETE_PROJECT|translate"
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>

  <div
    *ngIf="(projectService.archived$|async)?.length"
    class="component-wrapper"
  >
    <h2
      class="mat-h2"
      style="text-align: center; margin-top: 32px"
    >
      {{T.PP.ARCHIVED_PROJECTS|translate}}
    </h2>

    <mat-card
      *ngFor="let project of (projectService.archived$|async); trackBy:trackById;"
      class="project"
    >
      <div
        [ngStyle]="getThemeColor(project.theme?.primary || project.themeColor)"
        class="project-color"
      ></div>

      <div class="title">{{project.title}}</div>

      <div class="issue-providers">
        <mat-icon
          *ngIf="project.issueIntegrationCfgs?.JIRA?.isEnabled"
          [title]="T.PP.JIRA_CONFIGURED|translate"
          svgIcon="jira"
        ></mat-icon>
        <mat-icon
          *ngIf="project.issueIntegrationCfgs?.GITHUB?.repo"
          [title]="T.PP.GITHUB_CONFIGURED|translate"
          svgIcon="github"
        ></mat-icon>
        <mat-icon
          *ngIf="project.issueIntegrationCfgs?.GITLAB?.project"
          [title]="T.PP.GITLAB_CONFIGURED|translate"
          svgIcon="gitlab"
        ></mat-icon>
      </div>

      <div class="controls">
        <!--        <button (click)="unarchive(project.id)"-->
        <!--                [title]="T.PP.UN_ARCHIVE_PROJECT|translate"-->
        <!--                color=""-->
        <!--                mat-icon-button>-->
        <!--          <mat-icon>unarchive</mat-icon>-->
        <!--        </button>-->
        <!--        <button (click)="remove(project.id)"-->
        <!--                *ngIf="project.id !== (workContextService.activeWorkContextId$|async)"-->
        <!--                [title]="T.PP.DELETE_PROJECT|translate"-->
        <!--                color="warn"-->
        <!--                mat-icon-button>-->
        <!--          <mat-icon>delete</mat-icon>-->
        <!--        </button>-->
      </div>
    </mat-card>
  </div>
</div>
