<mat-dialog-content>
  <div class="form-wrapper">
    <owl-wrapper
      (triggerSubmit)="save()"
      [(dateTime)]="dateTime"
    ></owl-wrapper>

    <div class="additional-controls">
      <mat-form-field>
        <mat-label>{{(T.F.TASK.D_REMINDER_ADD.REMIND_AT|translate)}}</mat-label>
        <mat-select
          [(ngModel)]="selectedReminderCfgId"
          name="type"
          required="true"
        >
          <mat-option
            *ngFor="let remindOption of remindAvailableOptions; trackBy: trackByIndex"
            [innerHtml]="(remindOption.label|translate)"
            [value]="remindOption.value"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        *ngIf="isShowMoveToBacklog"
        class="move-to-backlog-wrapper"
      >
        <mat-checkbox
          [(ngModel)]="isMoveToBacklog"
          name="isListSubTasks"
        >
          <div
            class="text-wrap"
            style="max-width: 280px"
          >
            {{T.F.TASK.D_REMINDER_ADD.MOVE_TO_BACKLOG|translate}}
          </div>
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button
      (click)="close()"
      [title]="T.G.CANCEL|translate"
      color="primary"
      mat-button
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>

    <button
      (click)="remove()"
      *ngIf="isEdit"
      [title]="T.F.TASK.D_REMINDER_ADD.UNSCHEDULE|translate"
      color="primary"
      mat-stroked-button
      type="button"
    >
      <mat-icon>alarm_off</mat-icon>
    </button>

    <button
      (click)="save()"
      [disabled]="!dateTime"
      [title]="(reminder
            ?T.F.TASK.D_REMINDER_ADD.SCHEDULE
            :T.G.UPDATE)|translate"
      color="primary"
      mat-stroked-button
      type="submit"
    >
      <mat-icon *ngIf="!reminder">alarm_add</mat-icon>
      <mat-icon *ngIf="reminder">alarm</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
<!--</form>-->
