<div class="page-wrapper">
  <div class="component-wrapper">
    <h1
      class="mat-h1"
      style="display: flex; align-items: center"
    >
      <mat-icon style="margin-right: 8px">list</mat-icon>
      <span>{{T.PS.PROJECT_SETTINGS|translate}}</span>
    </h1>
    <section class="config-section">
      <config-section
        (save)="saveBasicSettings($event)"
        [cfg]="currentProject"
        [section]="basicFormCfg"
      ></config-section>
    </section>

    <section class="config-section">
      <config-section
        (save)="saveProjectThemCfg($event)"
        [cfg]="currentProjectTheme"
        [section]="projectThemeSettingsFormCfg"
      ></config-section>
    </section>

    <h2
      class="mat-h2"
      style="margin-top: 24px"
    >
      <span>{{T.PS.ISSUE_INTEGRATION|translate}}</span>
    </h2>
    <div *ngFor="let section of issueIntegrationFormCfg; trackBy:trackBySectionKey;">
      <section class="config-section">
        <config-section
          (save)="saveIssueProviderCfg($event)"
          [cfg]="getIssueIntegrationCfg(section.key)"
          [section]="section"
        ></config-section>
      </section>
    </div>
  </div>
</div>
