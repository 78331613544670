<h1 mat-dialog-title>{{T.F.WELCOME.D_WELCOME.TITLE|translate}}</h1>

<mat-dialog-content>
  <p>{{T.F.WELCOME.D_WELCOME.T1|translate}}</p>

  <p
    *ngIf="IS_TOUCH_ONLY"
    [innerHTML]="T.F.WELCOME.D_WELCOME.MOBILE_INFO|translate"
  ></p>

  <p>{{T.F.WELCOME.D_WELCOME.T2|translate}}</p>
  <ul>
    <li>
      <a
        target="_blank"
        tabindex="2"
        href="https://dev.to/johannesjo/getting-started-with-super-productivity-2791"
        >{{T.F.WELCOME.D_WELCOME.L1|translate}}</a
      >
    </li>
    <li>
      <a
        target="_blank"
        tabindex="2"
        href="https://www.youtube.com/watch?v=_mvMXWLsL-8&ab_channel=JohannesM"
        >{{T.F.WELCOME.D_WELCOME.L2|translate}}</a
      >
    </li>
    <li>
      <a
        target="_blank"
        tabindex="2"
        href="https://github.com/johannesjo/super-productivity/discussions/new?category=q-a"
        >{{T.F.WELCOME.D_WELCOME.L3|translate}}</a
      >
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    color="primary"
    class="welcome-ok-btn"
    mat-stroked-button
    autofocus="autofocus"
    (click)="close()"
    tabindex="0"
  >
    {{T.F.WELCOME.D_WELCOME.LETS_GO|translate}}
  </button>
</mat-dialog-actions>
