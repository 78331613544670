<form
  (ngSubmit)="submit()"
  [formGroup]="form"
>
  <formly-form
    (modelChange)="config=$event"
    [fields]="fields"
    [form]="form"
    [model]="config"
    [options]="options"
  >
  </formly-form>

  <div class="controls">
    <button
      [disabled]="!form.valid"
      class="btn btn-primary submit-button"
      color="primary"
      mat-raised-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{T.G.SAVE|translate}}
    </button>
  </div>
</form>

<!--<pre><code>{{config|json}}</code></pre>-->
