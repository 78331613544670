<div
  *ngIf="repeatCfg"
  class="repeat-task-projection"
  (click)="editTaskRepeatCfg()"
>
  <mat-icon svgIcon="repeat"></mat-icon>
  <div class="title">
    {{repeatCfg.title}}
    <!--   {{event.start|date:'HH:mm'}} – {{(event.start + event.duration)|date:'HH:mm'}}-->
  </div>
  <div class="estimate">{{repeatCfg.defaultEstimate|msToString}}</div>
</div>
