<owl-date-time-inline
  (doubleEnter)="submit()"
  (ngModelChange)="updateDateFromCal($event)"
  [firstDayOfWeek]="(firstDayOfWeek$|async)"
  [isNoMonthSquares]="true"
  [laterTodaySlots]="laterTodaySlots"
  [min]="now"
  [ngModelOptions]="{standalone:true}"
  [ngModel]="date"
  [sLaterToday]="T.DATETIME_SCHEDULE.LATER_TODAY|translate"
  [sNextWeek]="T.DATETIME_SCHEDULE.NEXT_WEEK|translate"
  [sPlaceholder]="T.DATETIME_SCHEDULE.PLACEHOLDER|translate"
  [sPressEnterToSubmit]="T.DATETIME_SCHEDULE.PRESS_ENTER_AGAIN|translate"
  [sTomorrow]="T.DATETIME_SCHEDULE.TOMORROW|translate"
  name="owlDate"
  ngDefaultControl
></owl-date-time-inline>
