<better-drawer-container
  (wasClosed)="close()"
  [isOpen]="isOpen$|async"
  [isOver]="isAlwaysOver || (layoutService.isRightPanelOver$|async)"
  [sideWidth]="40"
>
  <ng-container better-drawer-content>
    <ng-content></ng-content>
  </ng-container>

  <ng-container better-drawer-side>
    <div class="right-panel">
      <notes
        *ngIf="!(selectedTaskWithDelayForNone$|async) "
        [@slideInFromRight]
      ></notes>
      <task-additional-info
        [isOver]="isAlwaysOver || (layoutService.isRightPanelOver$|async)"
        [@taskAdditionalInfoTaskChange]=" selectedTaskWithDelayForNone?.id"
        [@.disabled]="isDisableTaskPanelAni"
        *ngIf="selectedTaskWithDelayForNone$|async as selectedTaskWithDelayForNone"
        [task]="selectedTaskWithDelayForNone"
      ></task-additional-info>
    </div>
  </ng-container>
</better-drawer-container>
